import store from '@/store'

const adminguard = (to, from, next) => {
    return next()
    // if(localStorage.getItem('systemauthtoken' + store.getters.authuser.id) != null && store.getters.authuser != null && store.getters.authuser.name ){
    //     return next()
    // }else{
    //     return next({ name: 'login' })
    // }
};


let routes = [
    { 
        path: "/",
        props: true,
        beforeEnter: adminguard,
        component: () => import("@/views/app/entry/main.vue"),
        title:'',

          children: [

            {
              path: "/",
              name: "welcome",
              props:true,
              beforeEnter: adminguard,
              component: () => import("@/views/app/entry/welcome.vue"),
              meta: {
              title: 'Leave your Suggestions so that we can make Improvements'
              }
            },
  
        ]
    },

    {
      path: "/suggest/riverflow-international",
      name: "suggest_riverflow",
      props:true,
      beforeEnter: adminguard,
      component: () => import("@/views/app/rifi/suggest.vue"),
      meta: {
      title: 'Riveflow International '
      }
    },

    {
      path: "/suggest/science-teachers-iniatitive",
      name: "suggest_scienceteachers",
      props:true,
      beforeEnter: adminguard,
      component: () => import("@/views/app/sti/suggest.vue"),
      meta: {
      title: 'Science Teachers Initiative'
      }
    },




]
export default routes;